<template>
  <div class="p-3">

    <Loading v-if="isLoading"/>

    <!-- Selector de fecha -->
    <div class="grid" v-show="!isLoading">
      <div class="col-12 sm:col-8 lg:col-3">
        <NightPicker @handleChange="updateDashboard"/>
      </div>
      <div class="col-12 sm:col-4">
        <span class="p-buttonset">
          <Button 
            @click="refreshDashboard" 
            icon="fas fa-sync" 
            class="p-button-outlined p-button-primary icon-center" 
            autofocus/>
          <Button 
            @click="printReports" 
            icon="fas fa-print" 
            class="p-button-outlined p-button-success icon-center" />

            <Button 
              @click="selectMultiple()" 
              class="p-button-outlined p-button-secondary icon-center">
              <i class="fas fa-layer-group mr-1"></i> MULTIPLE
              <Badge 
                :value="multiple.place.length" 
                severity="danger" 
                v-if="multiple.status"/>
          </Button>
        </span>
      </div>
    </div>

    <!-- Grupos -->
    <Group
      v-for="group in aryData" 
      :key="group.id" 
      :group="group" 
      v-show="!isLoading"
    />

    <PrintSelect 
      v-if="showDialogPrints" 
      :show="showDialogPrints"
      @handleClose="handleClose" 
    />

    <DetailMultiple 
      v-if="showDialogDetail" 
      :show="showDialogDetail" 
      :data="multiple" 
      @handleClose="handleClose"
    />

    <br><br>

	</div>
</template>

<script>
  
  import apiService from '../../services/apiService'
  import Group from '../Groups/Group'
  import PrintSelect from './Dialogs/PrintSelect'
  import Badge from 'primevue/badge';
  import DetailMultiple from './Dialogs/DetailMultiple'

  export default {
    components: {
      Group,
      PrintSelect,
      Badge,
      DetailMultiple
    },
    data(){
      return { 
        isLoading: true,
        aryData: [],
        nights: null,
        showDialogPrints: false,
        showDialogDetail: false,
        multiple: {
          status: false,
          place: [],
          reservation_date: null,
        },
        tracking: null,
      }
    },
    created (){
      this.emitter.on('reload-dashboard', () => {
        this.refreshDashboard();
      })
    },
    mounted(){
      this.nights = JSON.parse(this.$store.state.nights)
      let date = localStorage.getItem('date')
      this.updateDashboard(date ? date : this.nights[0].value)

      this.resetMultiple();
    },
    methods: {
      refreshDashboard(){
        let current = localStorage.getItem('date')
        this.updateDashboard((current) ? current : this.nights[0].value)
        this.resetMultiple()
      },
      
      printReports(){
        this.showDialogPrints = true
      },

      handleClose(reload = false){
        console.log("CIERRA")
        this.showDialogPrints = false
        this.showDialogDetail = false

        if(this.multiple && this.multiple.status){
          this.setTracking(1);
        }

        if(reload){
          this.updateDashboard(localStorage.getItem('date'));
        }
      },
      async updateDashboard(date){
        this.isLoading = true
        this.aryData = []
        this.resetMultiple()
        localStorage.removeItem('date');
        localStorage.setItem('date', date);
  
        apiService.Group.dashboard(date)
        .then((result) => {
          this.aryData = result
          this.isLoading = false
        })
      },
      selectMultiple(){
        if(!this.multiple.status){

          this.multiple.status = true;

          this.setTracking(1);
          this.FormSuccess("La selección multiple está activa.");

        }else{

          if(this.multiple.place.length){
            this.$confirm.require({
              message: `Existen ${ this.multiple.place.length } mesas seleccionadas ¿Como desea continuar?`,
              icon: 'fas fa-exclamation-triangle',
              rejectLabel: 'Descartar',
              acceptLabel: 'Procesar',
              acceptClass: 'p-button-success',
              rejectClass: 'p-button-danger',
              accept: () => {
                this.setTracking(0);
                this.showDialogDetail = true;
              },
              reject: () => {
                this.resetMultiple();
                this.FormSuccess("Se desactivó la selección multiple.");
                this.updateDashboard(localStorage.getItem('date'));
              }
            })
          }else{
            this.resetMultiple();
            this.FormSuccess("Se desactivó la selección multiple.");
          }
        }
        localStorage.setItem('multiReservation', JSON.stringify(this.multiple))
      },

      setTracking(status){
        if(status){
          this.tracking = setInterval(() => {
            this.multiple = JSON.parse(localStorage.getItem('multiReservation'));
          }, 500);
        }else{
          clearInterval(this.tracking)
        }
      },
      resetMultiple(){
        this.multiple = {
          status: false,
          place: [],
          reservation_date: null,
        },
        localStorage.setItem('multiReservation', JSON.stringify(this.multiple));
        this.setTracking(0);
      },
    }
  }
</script>