<template>
    <div>
        <Dialog 
            id="dialogPrintList"
            v-model:visible="showDialog" 
            :modal="true" 
            :style="{ width: '50vw' }"
            :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
            :closeOnEscape="false"
            :closable="false">
            <template #header>
                <div class="grid">
                    <div class="col-xs-12">
                        <h2 class="mt-0 mb-0 pb-0">IMPRIMIR LISTADOS</h2>
                    </div>
                </div>
            </template>

            <div class="card mt-1">
                <form @submit.prevent="handleReservation">
                    <div class="formgrid grid">
                        <div class="field col-12 col-sm-6 col-md-6 mt-3">
                            <label for="sector">Sector</label>
                            <MultiSelect 
                                id="sector" 
                                ref="sector" 
                                v-model="filter.group" 
                                placeholder="Todos"
                                optionValue="id"
                                optionLabel="description"
                                :filter="true"
                                :options="groups"
                                :showClear="true" 
                                class="w-full"
                            >
                            <template #option="slotProps">
                                <div class="flex align-items-center">
                                    <div>{{ `${slotProps.option.description} - ${slotProps.option.name}` }}</div>
                                </div>
                            </template>
                        </MultiSelect>
                        </div>
                        <div class="field col-12 col-sm-6 col-md-6 mt-3">
                            <label for="noche">Noche</label>
                            <NightPicker @handleChange="setNight"/>
                        </div>
                        <div class="field col-12 mt-3">
                            <label for="usuario">Usuario</label>
                            <MultiSelect 
                                id="usuario" 
                                ref="usuario" 
                                v-model="filter.user" 
                                placeholder="Todos"
                                optionValue="id"
                                optionLabel="fullName"
                                :filter="true"
                                :options="users"
                                :showClear="true" 
                                class="w-full"
                            />
                        </div>
                        <div class="field col-12 mt-3">
                            <label for="tipo">Tipo</label>
                            <div class="text-center">
                                <Button 
                                    class="p-button-sm p-button-secondary m-1" 
                                    label="LISTADO" 
                                    @click="handlePrint('list')"
                                    icon="fas fa-print" />

                                <Button 
                                    class="p-button-sm p-button-secondary m-1" 
                                    label="ETIQUETAS" 
                                    @click="handlePrint('tags')"
                                    icon="fas fa-print" />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            
            <template #footer>
                <div class="grid">
                    <div class="col-12 mt-4 text-left">
                        <Button 
                            class="p-button-sm p-button-outlined p-button-danger" 
                            label="CERRAR" 
                            @click="handleClose"
                            icon="fas fa-times" />
                    </div>
                </div>
            </template>
        </Dialog>

        <List 
            v-if="showDialogList" 
            :load="showDialogList"
            :data="data"
            :filename="filename"
            @handleClosePrint="handleClosePrint" />

        <Tag 
            v-if="showDialogTag"
            :load="showDialogTag"
            :data="data"
            :filename="filename"
            @handleClosePrint="handleClosePrint" />

    </div>
</template>

<script>
    import apiService from '../../../services/apiService';

    import List from './List.vue';
    import Tag from './Tag.vue';

    export default {
        name: 'Detail',
        components:{
            List,
            Tag
        },
        props:{
            show: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                showDialog: false,
                filter: {
                    date: null,
                    user: [],
                    group:[],
                    type: '',
                },
                data: null,
                filename: '',
                users: [],
                groups: [],
                showDialogList: false,
                showDialogTag: false
            }
        },
        created(){
            this.showDialog = this.show
            this.getUsers();
            this.getGroups();

            this.filter.date = localStorage.getItem('date')
        },

        methods: {
            handlePrint(type){
                this.filter.type = type;
                try{
                    if(!this.filter.date) throw "Debe seleccionar una noche para continuar."

                    apiService.Reservation.prints(this.filter)
                    .then((result) => {
                        if(result.status == 'success'){
                            this.data = result.data
                            this.filename = result.filename

                            if(this.filter.type == 'list'){
                                this.showDialogList = true;
                            }else{
                                this.showDialogTag = true;
                            }

                        }else{
                            this.FormErrors(result)
                        }
                    }).catch((e) => {
                        this.FormRequestFail(e)
                    })

                }catch(e){
                    this.FormRequestFail(e, true)
                }
            },
            setNight(night){
                this.filter.date = night
            },
            async getUsers(){
                apiService.User.get()
                .then((result) => {
                    this.users = result
                })
            },
            async getGroups(){
                apiService.Group.get()
                .then((result) => {
                    this.groups = result
                })
            },
            handleClose(){
                this.showDialog = false
                this.$emit('handleClose')
            },
            handleClosePrint(){
                this.resetForm()
            },
            resetForm(){
                this.data = []
                this.showDialogList = false
                this.showDialogTag = false
            }
        }
    }
</script>
