<template>
  <div v-show="!isLoading">

    <Groups/>

    <Loading v-if="isLoading"/>
  </div>
</template>

<script>

  import Groups from './Groups/Index.vue'

  export default {
    components: {
      Groups
    },
    data(){
      return { 
        isLoading: false,
      }
    },

    mounted(){
    },
    methods: {
    }
  }
</script>
