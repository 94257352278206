<template>
	<div>
		<div class="w-full h-4rem border-1 border-round text-center text-3xl pt-2 mt-2 border-dashed border-grey"
			v-if="group.gId === 2">PASARELA</div>
		<div class="p-0 m-0 my-2">
			<h2 class="uppercase ml-3 mt-0 text-400">{{group.gName}}</h2>
			<!-- Filas -->
			<div class="flex flex-row flex-nowrap overflow-x-auto md:justify-content-start lg:justify-content-center"
				v-for="place in group.places" :key="place.pId">
				<!-- Columnas -->
				<Place v-for="col in place" :key="col.pId" :col="col" />
			</div>
		</div>
	</div>
</template>

<script>
	import Place from '../Places/Place'

	export default {
		name: 'Group',
		components: {
			Place,
		},
		props: {
			group: {
				type: Object,
				default: null,
			},
		},
	}
</script>
