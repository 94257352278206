<template>
    <div>
        <Dialog 
            id="dialogDetail"
            v-model:visible="showDialog" 
            :modal="true" 
            :style="{ width: '75vw' }"
            :closeOnEscape="false"
            :closable="false"
            :class="{ 'free': placeAvailable, 'reserved': !placeAvailable }"
            class="p-dialog-maximized">
            <template #header>
                <div class="grid">
                    <div class="col-xs-12">
                        <h2 class="mt-0 mb-0 pb-0">FILA {{reservation.pRow}} - {{reservation.pName}}</h2>
                        <h3 class="mt-0 pt-0 ">{{reservation.gName}} ({{reservation.gDesc}})</h3>
                        <Tag class="text-base uppercase p-2" value="Primary" rounded v-if="!placeAvailable">
                            <span class="font-bold">Reserv&oacute;:</span> {{placeUser}}
                        </Tag>
                    </div>
                </div>
            </template>

            <div class="card mt-1">
                <form @submit.prevent="handleReservation">
                    <div class="formgrid grid">
                        <div class="field col-12 md:col-4 lg:col-4 mt-3">
                            <label for="record_date">Fecha</label>
                            <Dropdown 
                                v-model="record.reservation_date" 
                                :options="nights" 
                                optionLabel="name" 
                                @change="updateNights()"
                                optionValue="value" 
                                optionDisabled="unavailable"
                                :disabled="!allowReserve"
                                class="w-full"
                                :placeholder="labelNight">
                                <template #option="slotProps">
                                    <div>
                                        {{ slotProps.option.name }} -
                                        <Badge :severity="slotProps.option.unavailable ? 'danger' : 'success'"> {{ slotProps.option.unavailable ? 'RESERVADA' : 'LIBRE' }} </Badge>
                                    </div>
                                </template>
                            </Dropdown>
                        </div>
                        <div class="field col-12 md:col-4 lg:col-4 mt-3">
                            <label for="record_last_name">Apellido</label>
                            <InputText 
                                type="text" 
                                id="record_last_name" 
                                ref="record_last_name" 
                                v-model="record.last_name" 
                                class="w-full"
                                autocomplete="off"
                                :disabled="!placeAvailable || !allowReserve"
                            />
                        </div>
                        <div class="field col-12 md:col-4 lg:col-4 mt-3">
                            <label for="record_first_name">Nombre</label>
                            <InputText 
                                type="text" 
                                id="record_first_name" 
                                ref="record_first_name" 
                                v-model="record.first_name" 
                                class="w-full"
                                autocomplete="off"
                                :disabled="!placeAvailable || !allowReserve"
                            />
                        </div>
                        <div class="field col-12 mt-3">
                            <label for="record_observation">Observación</label>
                            <Textarea 
                                id="record_observation" 
                                ref="record_observation" 
                                v-model="record.observation" 
                                :disabled="!allowReserve"
                                class="w-full" cols="3" />
                        </div>

                        <div class="field col-12 md:col-4 lg:col-4 mt-3">
                            <label for="record_fpago">Forma de Pago</label>
                            <Dropdown 
                                id="record_fpago" 
                                ref="record_fpago" 
                                v-model="record.fpago" 
                                :disabled="!placeAvailable || !allowReserve"
                                :options="defaultData.comboFPago" 
                                optionLabel="label" 
                                optionValue="label"
                                placeholder="Forma de Pago" 
                                class="w-full"
                            />
                        </div>
                        <div class="field col-12 md:col-4 lg:col-4 mt-3">
                            <label for="record_extra">Adicionales</label>
                            <Dropdown 
                                id="record_extra" 
                                ref="record_extra" 
                                v-model="record.extra" 
                                :disabled="!placeAvailable && this.$store.state.user.id != reservation.rUserId"
                                :options="adicionales" 
                                optionLabel="label" 
                                optionValue="value"
                                placeholder="Adicionales" 
                                class="w-full"
                            />
                            <!-- <Checkbox 
                                id="record_extra" 
                                ref="record_extra" 
                                v-model="record.extra" 
                                :disabled="!placeAvailable && this.$store.state.user.id != reservation.rUserId"
                                :trueValue="1"
                                :falseValue="0"
                                :binary="true" />
                            <label for="record_extra">{{ reservation.pRow === 4 ? 'Banqueta' : 'Silla' }} Extra</label> -->
                        </div>
                        <div class="field col-6 mt-1" v-if="!placeAvailable">
                            <label for="record_user">Usuario</label>
                            <InputText 
                                type="text" 
                                id="record_user" 
                                ref="record_user" 
                                :value="reservation.rUser" 
                                class="w-full"
                                autocomplete="off"
                                disabled
                            />
                        </div>
                        <div class="field col-6 mt-1" v-if="!placeAvailable">
                            <label for="record_ts">Fecha</label>
                            <InputText 
                                type="text" 
                                id="record_ts" 
                                ref="record_ts" 
                                :value="reservation.rDateStamp" 
                                class="w-full"
                                autocomplete="off"
                                disabled
                            />
                        </div>
                    </div>
                </form>
            </div>
            
            <template #footer>
                <div class="grid">
                    <div class="col-2 mt-4 text-left">
                        <Button 
                            class="p-button-sm p-button-outlined p-button-danger" 
                            label="CERRAR" 
                            @click="handleClose"
                            icon="fas fa-times" />
                    </div>
                    <div class="col-10 mt-4 text-right" v-if="placeAvailable">
                        <Button 
                            class="p-button-sm p-button-outlined" 
                            label="CONFIRMAR" 
                            :disabled="!placeAvailable || record.reservation_date == null || record.last_name == '' || record.first_name == ''"
                            @click="handleReservation"
                            icon="fas fa-lock" />
                    </div>
                    <div class="col-10 mt-4 text-right white-space-nowrap" v-if="!placeAvailable && this.$store.state.user.id == reservation.rUserId">
                        <SplitButton label="Operaciones" icon="fas fa-cog" :model="items" />
                    </div>
                </div>
            </template>
        </Dialog>

        <Ticket 
            v-if="showDialogPrint" 
            :data="reservation" 
            :load="showDialogPrint" 
            @handleClosePrint="handleClosePrint" />

        <TicketMultiple 
            v-if="showDialogPrintMultiple" 
            :uuid="uuid"
            :load="showDialogPrintMultiple" 
            @handleClosePrint="handleClosePrint" />
    
    </div>
</template>

<script>

    import apiService from '../../../services/apiService';
    import SplitButton from 'primevue/splitbutton';

    import Ticket from './Ticket';
    import Tag from 'primevue/tag';
    import Badge from 'primevue/badge';
    import TicketMultiple from '../../Groups/Dialogs/TicketMultiple';
    import moment from 'moment-timezone'

    export default {
        name: 'Detail',
        components:{
            Ticket,
            Tag,
            SplitButton,
            Badge,
            TicketMultiple
        },
        props:{
            show: {
                type: Boolean,
                default: false,
            },
            data: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                reservation: null,
                showDialog: false,
                nights: [],
                record:{
                    place_id: null,
                    reservation_date: null,
                    last_name: '',
                    first_name: '',
                    observation: '',
                    extra: false,
                    fpago: null,
                },
                placeStatus: '-',
                placeAvailable: true,
                placeUser: '',
                showDialogPrint: false,
                showDialogPrintMultiple: false,
                allowReserve: false,
                uuid: null,
                adicionales: [],
                items: [
                    {
                        label: 'LIBERAR',
                        icon: 'fas fa-lock-open',
                        command: () => {
                            this.handleEndReservation()
                        }
                    },
                    {
                        label: 'ACTUALIZAR',
                        icon: 'fas fa-save',
                        command: () => {
                            this.handleUpdateReservation()
                        }
                    },
                    {
                        label: 'IMPRIMIR',
                        icon: 'fas fa-print',
                        command: () => {
                            if(this.uuid){
                                this.showDialogPrintMultiple = true
                            }else{
                                this.showDialogPrint = true
                            }
                        }
                    },
                ],
                labelNight: 'Seleccionar Noche',
            }
        },
        created(){
            this.showDialog = this.show
            this.reservation = this.data;
            this.placeUser = this.data.rUser;
            this.record.place_id = this.reservation.pId
            
            this.updateNights();
            this.allowToReserve();

            this.adicionales = [
                {
                    "label": `0 - Sin Adicionales`,
                    "value": 0,
                }
            ];

            for (let index = 1; index <= this.reservation.adicionales; index++) {
                this.adicionales.push({
                    "label": `${index} - ${(index === 1 ? 'Adicional' : 'Adicionales')} (+${this.design.Currency((this.reservation.extra * index))})`,
                    "value": index
                })
            }
            
            // Si la mesa está reservada, cargo los datos
            if(this.reservation.rId){
                this.loadReservationData();
            }else{
                this.loadReservationData(0)
            }
        },

        methods: {
            updateNights(){
                apiService.Catalog.nightsStatusPlace(this.record.place_id)
                .then((result) => {
                    this.nights = result
                    this.checkDateStatus()
                }).catch((e) => {
                    this.FormRequestFail(e)
                })
            },
            handleReservation(){
                let d = moment.utc(this.record.reservation_date, 'YYYY-MM-DD').format('DD-MM-YYYY');
                let n = this.record.last_name.toUpperCase()+' '+this.record.first_name.toUpperCase()

                this.$confirm.require({
                    message: `Continuar y registrar la reservación para el dia ${d} a nombre de ${n} ?`,
                    header: 'Confirmación',
                    accept: () => {
                        var form = this.globalMethods.getFormData(this.record);
                        this.isLoading = true
                        apiService.Reservation.start(form)
                        .then((result) => {
                            if(result.status == 'success'){
                                this.FormSuccess(result.message)
                                this.reservation = result.reservation
                                this.labelNight = result.reservation.labelNight
                                this.updateNights()
                            }else{
                                this.FormErrors(result)
                                this.isLoading = false
                            }
                        }).catch((e) => {
                            this.FormRequestFail(e)
                            this.isLoading = false
                        })
                    },
                    reject: () => {
                        //callback to execute when user rejects the action
                    }
                });
            },
            handleEndReservation(){
                this.$confirm.require({
                    message: `Continuar y finalizar la reservación?`,
                    header: 'Confirmación',
                    accept: () => {
                        this.isLoading = true
                        apiService.Reservation.end(this.reservation.rId)
                        .then((result) => {
                            if(result.status == 'success'){
                                this.FormSuccess(result.message)
                                this.isLoading = false
                                this.loadReservationData(0)
                                this.reservation.rId = null;
                                this.reservation.rLName = null;
                                this.reservation.rFName = null;
                                this.reservation.rDate = null;
                                this.reservation.rDateStamp = null;
                                this.reservation.rObs = null;
                                this.reservation.rUser = null;
                                this.reservation.reserved = null;
                                this.reservation.rFPago = null;
                                this.updateNights()

                            }else{
                                this.FormErrors(result)
                                this.isLoading = false
                            }
                        }).catch((e) => {
                            this.FormRequestFail(e)
                            this.isLoading = false
                        })
                    },
                    reject: () => {
                        //callback to execute when user rejects the action
                    }
                });
            },
            handleUpdateReservation(){
                this.$confirm.require({
                    message: `Continuar y actualizar la reservación?`,
                    header: 'Confirmación',
                    accept: () => {
                        this.record.rId = parseInt(this.reservation.rId);
                        var form = this.globalMethods.getFormData(this.record);
                        this.isLoading = true
                        apiService.Reservation.update(form)
                        .then((result) => {
                            if(result.status == 'success'){
                                this.FormSuccess(result.message)
                                //this.reservation.extra = this.record.extra;
                                this.reservation = result.reservation
                                this.isLoading = false
                            }else{
                                this.FormErrors(result)
                                this.isLoading = false
                            }
                        }).catch((e) => {
                            this.FormRequestFail(e)
                            this.isLoading = false
                        })
                    },
                    reject: () => {
                        //callback to execute when user rejects the action
                    }
                });
            },
            checkDateStatus(){
                apiService.Place.status(this.record.place_id, this.record.reservation_date)
                .then((result) => {
                    this.placeStatus = result.status
                    this.placeAvailable = result.available
                    this.placeUser = result.user

                    if(result.available){
                        this.record.last_name = ''
                        this.record.first_name = ''
                        this.record.observation = ''
                        this.record.extra = false
                        this.placeStatus = 'DISPONIBLE';
                        this.placeAvailable = true;
                        this.record.fpago = null;
                    }

                }).catch((e) => {
                    this.FormRequestFail(e)
                })
            },
            loadReservationData(reserved = 1){
                if(reserved){
                    this.record.reservation_date = this.reservation.reservation_date
                    this.record.last_name = this.reservation.rLName
                    this.record.first_name = this.reservation.rFName
                    this.record.observation = this.reservation.rObs
                    this.record.extra = this.reservation.rExtra
                    this.record.fpago = this.reservation.rFPago
                    this.labelNight = this.reservation.labelNight
                    this.uuid = this.reservation.rUuid
                }else{
                    this.record.reservation_date = this.reservation.showDate
                    this.record.last_name = ''
                    this.record.first_name = ''
                    this.record.observation = ''
                    this.record.extra = false
                    this.placeStatus = 'DISPONIBLE';
                    this.placeAvailable = true;
                    this.record.fpago = null;
                }
            },
            allowToReserve(){

                let aryAllow = []

                if(this.reservation.uAssign){
                    aryAllow = this.reservation.uAssign.split(",");
                }else{
                    aryAllow = ['*'];
                }

                this.allowReserve = aryAllow.includes(this.$store.state.user.id.toString()) || aryAllow.includes('*') ? true : false
            },
            handleClose(){
                this.record.place_id = null
                this.last_name = ''
                this.first_name = ''
                this.observation = ''
                this.extra = false

                this.showDialog = false
                this.placeStatus = '-'
                this.placeAvailable = null
                this.$emit('handleClose', this.reservation);
            },
            handleClosePrint(){
                this.showDialogPrint = false;
                this.showDialogPrintMultiple = false;
            },
        }
    }
</script>

<style>
    .free > .p-dialog-header{
        background:#c5ffc5 !important;
    }
    .reserved > .p-dialog-header{
        background:#ffcd82 !important;
    }
    .p-dialog .p-dialog-footer button{
        margin: 0 !important;
    }
</style>

