<template>
<div>
  <Dialog 
    :closeOnEscape="false"
    :closable="false"
    v-model:visible="showDialogPrint" 
    :style="{width: '90vw'}" 
    class="p-dialog-maximized"
    :maximizable="true">
    <div id="printArea" v-if="data.rId">
        <div style="margin: 0 auto; height: 142mm; width: 99mm;"> <!-- border: solid #000 !important; border-width: 2px !important; -->
        <div style="width:100%; text-align: center; font-family:'Courier New', Courier, monospace;">
            <p style="font-weight: 800; font-size:18px; margin-top: 40px;">COMPROBANTE DE RESERVACIÓN</p>
            <p style="font-size: 16px;">TICKET N° {{data.rId}}</p>
        </div>
        <hr style="width:80%; border-style: none none dotted;">
        <div style="width:80%; font-family:'Courier New', Courier, monospace; padding-left:38px; text-align: center;">
            <p style="font-size: 15px;"><span style="font-weight: bold;">FILA: </span> {{data.pRow}} | <span style="font-weight: bold;">MESA: </span> {{data.pCol}}</p>
            <p style="font-size: 15px;">{{data.gName}} ({{data.gDesc}})</p>
        </div>
        <hr style="width:80%; border-style: none none dotted;">
        <div style="width:80%; font-family:'Courier New', Courier, monospace; padding-left:38px;">
            <p style="font-size: 15px;"><span style="font-weight: bold;">FECHA </span> {{data.rDate}}</p>
            <p style="font-size: 15px;"><span style="font-weight: bold;">NOMBRE </span> {{data.rLName +', '+data.rFName}}</p>
            <p style="font-size: 15px;"><span style="font-weight: bold;">VALOR </span> {{this.design.Currency(data.rPrecio)}}</p>
            <p style="font-size: 15px;"><span style="font-weight: bold;">METODO </span> {{data.rFPago}}</p>
            <p style="font-size: 15px;"><span style="font-weight: bold;">{{data.pRow === 4 ? 'BANQUETA' : 'SILLA'}} EXTRA</span> {{data.rExtra ? data.rExtra : 'NO'}}</p>
            <p style="font-size: 15px;"><span style="font-weight: bold;">OBSERVACIONES </span> {{data.rObs}}</p>
        </div>
        <hr style="width:80%; border-style: none none dotted;">
        <div style="width:100%; font-family:'Courier New', Courier, monospace; padding-left:38px;">
            <p style="font-size: 14px;"><span style="font-weight: bold;">Atendió: </span> {{data.rUser}}</p>
            <p style="font-size: 11px;"><span style="font-weight: bold;"></span> {{data.rDateStamp}}</p>
        </div>
        <hr style="width:80%; border-style: none none dotted;">
        <div class="end-text" style="width:100%; font-size: 20px; padding-top:25px; text-align: center; font-family:'Courier New', Courier, monospace;">
            Muchas Gracias!
        </div>
        </div>
    </div>

    <template #footer>
        <div class="grid">
        <div class="col-3 sm:col-3 text-left">
            <Button @click="closeDialog()" label="CERRAR" icon="fas fa-times" class="p-button-outlined p-button-sm p-button-danger" />
        </div>
        <div class="col-9 sm:col-9 text-right">
            <Button @click="exportToPDF()" icon="fas fa-file-pdf" class="p-button-outlined p-button-sm p-button-primary" autofocus label="PDF"/>
            <Button @click="print()" icon="fas fa-print" class="p-button-outlined p-button-sm p-button-primary" autofocus label="IMPRIMIR"/>
        </div>
        </div>
    </template>
  </Dialog>

  <div>

   </div>
</div>

</template>

<script>
	import html2pdf from 'html2pdf.js'

    export default {
        props:{
            data: {
                type: Object,
                default: null,
            },
            load: {
                type: Boolean,
                default: false,
            }
        },
        data(){
            return {
                isLoading: false,
                showDialogPrint: false,
                ticket: [],
            }
        },
        mounted(){
            this.showDialogPrint = this.load
            //this.print();
        },
        methods: {
            async print () {
                this.$htmlToPaper('printArea');
            },
            closeDialog(){
                this.$emit('handleClosePrint', '');
                this.showDialogPrint = false;
            },
			exportToPDF () {
                let name = `reserva_nro_${this.data.rId}_${this.data.rDate}.pdf`
                let element = document.getElementById('printArea');
				html2pdf(element, {
					margin: 1,
					filename: name,
					image: { type: 'jpeg', quality: 0.98 },
					html2canvas: { dpi: 300, letterRendering: true },
					jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
				})
			}
        }
    }
</script>