<template>
<div>
  <Dialog 
    :closeOnEscape="false"
    :closable="false"
    v-model:visible="showDialogPrint" 
    :style="{ width: '90vw' }" 
    class="p-dialog-maximized"
    :maximizable="true">
    <div id="printArea" v-if="uuid">
        <div style="margin: 0 auto; height: 142mm; width: 99mm;">
        <div style="width:100%; text-align: center; font-family:'Courier New', Courier, monospace;">
            <p style="font-weight: 800; font-size:18px; margin-top: 40px;">COMPROBANTE DE RESERVACIÓN</p>
            <p style="font-size: 16px;">TICKET N° {{ info.id }}</p>
        </div>
        <hr style="width:80%; border-style: none none dotted;">
        <div style="width:80%; font-family:'Courier New', Courier, monospace; padding-left:75px; text-align: center;">
            <p style="font-size: 15px;">
                <span style="font-weight: bold;">UBICACIONES</span>
            </p>
            <p style="font-size: 13px;" v-for="(place, index) in info.places" v-bind:key="index">{{place}}</p>
        </div>
        <hr style="width:80%; border-style: none none dotted;">
        <div style="width:80%; font-family:'Courier New', Courier, monospace; padding-left:38px;">
            <p style="font-size: 15px;"><span style="font-weight: bold;">FECHA </span> {{info.date}}</p>
            <p style="font-size: 15px;"><span style="font-weight: bold;">NOMBRE </span> {{info.name}}</p>
            <p style="font-size: 15px;"><span style="font-weight: bold;">VALOR </span> {{this.design.Currency(info.price)}}</p>
            <p style="font-size: 15px;"><span style="font-weight: bold;">METODO </span> {{info.fpago}}</p>
            <p style="font-size: 15px;"><span style="font-weight: bold;">ESPACIO EXTRA</span> {{info.extra}}</p>
            <p style="font-size: 15px;"><span style="font-weight: bold;">OBSERVACIONES </span> {{info.observations}}</p>
        </div>
        <hr style="width:80%; border-style: none none dotted;">
        <div style="width:100%; font-family:'Courier New', Courier, monospace; padding-left:38px;">
            <p style="font-size: 14px;"><span style="font-weight: bold;">Atendió: </span> {{info.user}}</p>
            <p style="font-size: 11px;"><span style="font-weight: bold;"></span> {{info.stamp}}</p>
        </div>
        <hr style="width:80%; border-style: none none dotted;">
        <div class="end-text" style="width:100%; font-size: 20px; padding-top:25px; text-align: center; font-family:'Courier New', Courier, monospace;">
            Muchas Gracias!
        </div>
        </div>
    </div>

    <template #footer>
        <div class="grid">
        <div class="col-3 sm:col-3 text-left">
            <Button @click="closeDialog()" label="CERRAR" icon="fas fa-times" class="p-button-outlined p-button-sm p-button-danger" />
        </div>
        <div class="col-9 sm:col-9 text-right">
            <Button @click="exportToPDF()" icon="fas fa-file-pdf" class="p-button-outlined p-button-sm p-button-primary" autofocus label="PDF"/>
            <Button @click="print()" icon="fas fa-print" class="p-button-outlined p-button-sm p-button-primary" autofocus label="IMPRIMIR"/>
        </div>
        </div>
    </template>
  </Dialog>

  <div>

   </div>
</div>

</template>

<script>
	import html2pdf from 'html2pdf.js'
    import apiService from '../../../services/apiService'
    export default {
        props:{
            uuid: {
                type: String,
                default: null,
            },
            load: {
                type: Boolean,
                default: false,
            }
        },
        data(){
            return {
                isLoading: false,
                showDialogPrint: false,
                ticket: [],
                info: {
                    id: '',
                    places: [],
                    name: '',
                    date: '',
                    price: '',
                    fpago: '',
                    extra: '-',
                    observations: '',
                    user: '',
                    stamp: '',
                    fname: '',
                }
            }
        },
        mounted(){
            this.showDialogPrint = this.load
            this.getTicketMultiple()
        },
        methods: {
            async print () {
                this.$htmlToPaper('printArea');
            },
            closeDialog(){
                this.$emit('handleClosePrint', '');
                this.showDialogPrint = false;
            },
			exportToPDF () {
                let element = document.getElementById('printArea');
				html2pdf(element, {
					margin: 1,
					filename: this.info.fname,
					image: { type: 'jpeg', quality: 0.98 },
					html2canvas: { dpi: 300, letterRendering: true },
					jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
				})
			},
            getTicketMultiple(){
                apiService.Reservation.ticketMultiple(this.uuid)
                .then((result) => {
                    this.info = result
                }).catch((e) => {
                    this.FormRequestFail(e)
                })
            }
        }
    }
</script>