<template>
    <div>
        <Dialog 
            id="dialogDetailMultiple"
            v-model:visible="showDialog" 
            :modal="true" 
            :style="{ width: '75vw' }"
            :closeOnEscape="false"
            :closable="false"
            :class="{ 'free': !uuid, 'reserved': uuid }"
            class="p-dialog-maximized">
            <template #header>
                <div class="grid">
                    <div class="col-12">
                        <h2 class="mt-0 mb-0 pb-0">RESERVA MULTIPLE</h2>
                        <h3 class="m-0 p-0 " v-for="row in reference.places" v-bind:key="row">{{row}}</h3>
                    </div>
                </div>
            </template>

            <div class="card mt-1">
                <form @submit.prevent="handleReservation">
                    <div class="formgrid grid">
                        <div class="field col-12 md:col-4 lg:col-4 mt-3">
                            <label for="record_date">Fecha</label>
                            <InputText 
                                type="text" 
                                id="record_date" 
                                ref="record_date" 
                                v-model="reference.night" 
                                class="w-full"
                                autocomplete="off"
                                disabled
                            />
                        </div>
                        <div class="field col-12 md:col-4 lg:col-4 mt-3">
                            <label for="record_last_name">Apellido</label>
                            <InputText 
                                type="text" 
                                id="record_last_name" 
                                ref="record_last_name" 
                                v-model="record.last_name" 
                                class="w-full"
                                autocomplete="off"
                            />
                        </div>
                        <div class="field col-12 md:col-4 lg:col-4 mt-3">
                            <label for="record_first_name">Nombre</label>
                            <InputText 
                                type="text" 
                                id="record_first_name" 
                                ref="record_first_name" 
                                v-model="record.first_name" 
                                class="w-full"
                                autocomplete="off"
                            />
                        </div>
                        <div class="field col-12 mt-3">
                            <label for="record_observation">Observación</label>
                            <Textarea 
                                id="record_observation" 
                                ref="record_observation" 
                                v-model="record.observation" 
                                class="w-full" cols="3" />
                        </div>

                        <div class="field col-12 md:col-4 lg:col-4 mt-3">
                            <div class=" field-checkbox">
                                <Dropdown 
                                    id="record_fpago" 
                                    ref="record_fpago" 
                                    v-model="record.fpago" 
                                    :options="defaultData.comboFPago" 
                                    optionLabel="label" 
                                    optionValue="label"
                                    placeholder="Forma de Pago" 
                                    class="w-full"
                                />
                            </div>
                        </div>
                        <div class="field col-12 mt-3">
                            <div class=" field-checkbox">
                                <Checkbox 
                                    id="record_extra" 
                                    ref="record_extra" 
                                    v-model="record.extra" 
                                    :trueValue="1"
                                    :falseValue="0"
                                    :binary="true" />
                                <label for="record_extra">Espacio Extra <small>(Aplica a todas las ubicaciones elegidas)</small></label>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            
            <template #footer>
                <div class="grid">
                    <div class="col-2 mt-4 text-left">
                        <Button 
                            class="p-button-sm p-button-outlined p-button-danger" 
                            label="CERRAR" 
                            @click="handleClose"
                            icon="fas fa-times" />
                    </div>
                    <div class="col-10 mt-4 text-right">
                        <Button 
                            class="p-button-sm p-button-outlined" 
                            label="CONFIRMAR" 
                            :disabled="record.reservation_date == null || record.last_name == '' || record.first_name == ''"
                            @click="handleReservation"
                            v-if="!uuid"
                            icon="fas fa-lock" />

                        <Button 
                            class="p-button-sm p-button-outlined" 
                            label="IMPRIMIR"
                            v-if="uuid" 
                            :disabled="record.reservation_date == null || record.last_name == '' || record.first_name == ''"
                            @click="handlePrint"
                            icon="fas fa-print" />
                    </div>
                   
                </div>
            </template>
        </Dialog>

        <TicketMultiple 
            v-if="showDialogPrintMultiple" 
            :uuid="uuid"
            :load="showDialogPrintMultiple" 
            @handleClosePrint="handleClosePrint" />
    </div>
</template>

<script>

    import apiService from '../../../services/apiService';
    import TicketMultiple from './TicketMultiple';
    import moment from 'moment-timezone'

    export default {
        name: 'DetailMultiple',
        components:{
            TicketMultiple,
        },
        props:{
            show: {
                type: Boolean,
                default: false,
            },
            data: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                showDialog: false,
                showDialogPrintMultiple: false,
                record: {
                    status: false,
                    place: [],
                    reservation_date: null,
                    last_name: '',
                    first_name: '',
                    observation: '',
                    fpago: null,
                    extra: null,
                },
                reference: [],
                uuid: null
            }
        },
        created(){
            this.showDialog = this.show;
            this.record.status = this.data.status;
            this.record.place = this.data.place;
            this.record.reservation_date = moment.utc(localStorage.getItem('date'), 'YYYY-MM-DD').format('DD-MM-YYYY');
            this.getReference();
        },
        methods: {
            handleReservation(){
                this.$confirm.require({
                    message: `Continuar y registrar la reservación multiple para el dia ${this.record.reservation_date} a nombre de ${this.record.last_name.toUpperCase()+' '+this.record.first_name.toUpperCase()} ?`,
                    header: 'Confirmación',
                    accept: () => {
                        var form = this.globalMethods.getFormData(this.record);
                        this.isLoading = true
                        apiService.Reservation.startMultiple(form)
                        .then((result) => {
                            if(result.status == 'success'){
                                this.uuid = result.uuid;
                                this.FormSuccess(result.message)
                            }else{
                                this.FormErrors(result)
                                this.isLoading = false
                            }
                        }).catch((e) => {
                            this.FormRequestFail(e)
                            this.isLoading = false
                        })
                    },
                    reject: () => {
                        //callback to execute when user rejects the action
                    }
                });
            },
            handleClose(){
                this.record.place_id = null
                this.last_name = ''
                this.first_name = ''
                this.observation = ''
                this.extra = false

                this.showDialog = false
                this.placeStatus = '-'
                this.placeAvailable = null
                this.$emit('handleClose', true);
            },
            handlePrint(){
                this.showDialogPrintMultiple = true;
            },
            handleClosePrint(){
                this.showDialogPrintMultiple = false;
            },
            getReference(){
                apiService.Place.reference(this.record)
                .then((result) => {
                    this.reference = result
                }).catch((e) => {
                    this.FormRequestFail(e)
                })
            }
        }
    }
</script>

<style>
    .free > .p-dialog-header{
        background:#c5ffc5 !important;
    }
    .reserved > .p-dialog-header{
        background:#ffcd82 !important;
    }
    .p-dialog .p-dialog-footer button{
        margin: 0 !important;
    }
</style>